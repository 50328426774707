.pkg-select-offers-card {
  width: 90%;
  position: relative;
  z-index: 1;
  right: 60px;
  background: #F7F7FC;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  align-self: center;
  padding: 35px;

  h4 {
    font-size: .789rem;
  }

  .work-from-anywhere {
    font-size: .789rem;
  }

  .maximise-productivity {
    font-size: .789rem;
  }
}