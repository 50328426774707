.chip {
  background: $colorLine;
  font-size: $fontSizeSmall;
  color: $colorHeading;
  line-height: 1em;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: 5px;
  .chip-label {
    padding: 0 12px;
  }
  &.chip-outline {
    background: transparent !important;
    box-shadow: inset 0 0 0 1px $colorLine;
  }
  &.chip-media {
    position: relative;
    padding-left: 20px;
    img {
      width: 25px;
      height: 25px;
      border-radius: 100px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .chip-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 26px;
    height: 26px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: $colorText;
    color: #fff;
    font-size: 14px;
  }
  .chip-delete {
    width: 26px;
    margin-left: -10px;
    height: 26px;
    display: inline-flex;
    color: $colorHeading;
    opacity: 0.7;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 100px;
    &:hover,
    &:active {
      opacity: 1;
    }
  }
}
.chip-primary {
  background: $colorPrimary !important;
  color: #fff;
  .chip-delete {
    color: #fff;
  }
  &.chip-outline {
    color: $colorPrimary !important;
    box-shadow: inset 0 0 0 1px $colorPrimary !important;
  }
}
.chip-danger {
  background: $colorDanger !important;
  color: #fff;
  .chip-delete {
    color: #fff;
  }
  &.chip-outline {
    color: $colorDanger !important;
    box-shadow: inset 0 0 0 1px $colorDanger !important;
  }
}
.chip-success {
  background: $colorGreen !important;
  color: #fff;
  .chip-delete {
    color: #fff;
  }
  &.chip-outline {
    color: $colorSuccess !important;
    box-shadow: inset 0 0 0 1px $colorSuccess !important;
  }
}
.chip-warning {
  background: $colorWarning !important;
  color: #fff;
  .chip-delete {
    color: #fff;
  }
  &.chip-outline {
    color: $colorWarning !important;
    box-shadow: inset 0 0 0 1px $colorWarning !important;
  }
}
.chip-info {
  background: $colorInfo !important;
  color: #fff;
  .chip-delete {
    color: #fff;
  }
  &.chip-outline {
    color: $colorInfo !important;
    box-shadow: inset 0 0 0 1px $colorInfo !important;
  }
}
.chip-light {
  background: #fff !important;
  color: #222 !important;
  .chip-delete {
    color: #222 !important;
  }
  &.chip-outline {
    color: #fff !important;
    box-shadow: inset 0 0 0 1px #fff !important;
  }
}
.chip-dark {
  background: #222 !important;
  color: #fff;
  .chip-delete {
    color: #fff;
  }
  &.chip-outline {
    color: #222 !important;
    box-shadow: inset 0 0 0 1px #222 !important;
  }
}
