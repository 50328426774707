.feature-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  overflow: hidden;
}

.feature-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 60px;
  color: #2c3e50;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 20px;
}

.feature-card {
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-icon {
  width: 70px;
  height: 70px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  font-size: 2rem;
  color: white;
  position: relative;
}

.feature-icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  filter: blur(15px);
  opacity: 0.3;
  z-index: -1;
}

.feature-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 15px 0;
  color: #2c3e50;
}

.feature-card p {
  color: #6c757d;
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 768px) {
  .feature-section {
    padding: 60px 0;
  }

  .feature-heading {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

/* Add smooth transition for all hover effects */
.feature-card, .feature-icon {
  transition: all 0.3s ease;
}

/* Add a subtle gradient background to cards on hover */
.feature-card:hover {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
}
