.imaged{
    height: auto;
    border-radius: $borderRadius;
    &.w16{
        width: 16px !important;
    }
    &.w24{
        width: 24px !important;
    }
    &.w32{
        width: 32px !important;
    }
    &.w36{
        width: 36px !important;
    }
    &.w48{
        width: 48px !important;
    }
    &.w64{
        width: 64px !important;
    }
    &.w76{
        width: 76px !important;
    }
    &.w86{
        width: 86px !important;
    }
    & .w100{
        width: 100px !important;
    }
    &.w120{
        width: 120px !important;
    }
    & .w140{
        width: 140px !important;
    }
    &.w160{
        width: 160px !important;
    }
    &.w180{
        width: 180px !important;
    }
    &.w200{
        width: 200px !important;
    }
    &.img-fluid{
        width: 100%;
    }
    &.rounded{
        border-radius: 100% !important;
    }
    &.radius{
        border-radius: $borderRadius !important;
    }
    &.square{
        border-radius: 0 !important;
    }

}
.imaged-left {
    height: auto;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    &.w76{
        width: 76px !important;
    }
    &.w86{
        width: 86px !important;
    }
    &.w100{
        width: 100px !important;
    }
    &.w120{
        width: 120px !important;
    }
    &.w130{
        width: 130px !important;
    }
    &.w140{
        width: 140px !important;
    }
    &.w160{
        width: 160px !important;
    }
}

.on-mobile.image-siblings-container {
  width: 100%;
  height: 100px;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  flex-basis: unset;
  max-height: none;
}

.on-mobile.image-siblings-container > div {
  display: inline-block;
  height: 100%;
  margin-right: 5px;
}

.on-mobile .sibling-images {
  height: 100%;
  width: 150px !important;
  object-fit: cover !important;
}
