.pkg-select-card {
  width: 100%;
  height: 500px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 45px;

  .heading {
    .desc {
      font-size: .789rem;
    }

    .title {
      font-size: 1.189rem;
      margin-top: -5px;
      font-style: normal;
      font-weight: 500;
      color: #170F49;
    }
  }

  .cost {
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    color: #170F49;
  }
}