
body{
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $bodyLineHeight;
  letter-spacing: $bodyLetterSpacing;
  color: $colorText;
  background: $bodyBackground;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  color: $colorPrimary;
  outline: 0 !important;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus{
    outline: 0 !important;
    color: $colorPrimary;
    text-decoration: none;
  }
}
// hide all the scroll bars
::-webkit-scrollbar {
  width: 0;
}

.mobile-view.with-spinner {
  //height: calc(100% - 40px);
  height: calc(100vh - 126px);

  > div.section {
    height: 100%;
  }

  .perfect-center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container-for-perfect-center {
  height: calc(100vh - 300px);

  .perfect-center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
