.faq-page {
  header {
    background: url('../../../../assets/img/faq-background.png') no-repeat center 50%/cover;
    padding-bottom: 150px;
    //background: radial-gradient(rgb(255, 255, 255), rgb(251, 161, 162));
  }
}

.faq-page.mobile {
  header {
    background: url('../../../../assets/img/faq-background.png') 74% 35%;
  }

  .menu-items {
    > p {
      margin-bottom: 0 !important;
    }
  }
}