
.rounded{
  border-radius: $borderRadius !important;
}
// Col
#appCapsule .row {
  margin-left: -8px;
  margin-right: -8px;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12{
  padding-left: 8px;
  padding-right: 8px;
}
// Spacing

#appCapsule .m-0 {
  margin: 0 !important;
}
#appCapsule .m-05{
  margin: 5px !important
}
#appCapsule .m-1 {
  margin: 8px !important;
}
#appCapsule .m-2 {
  margin: 16px !important;
}
#appCapsule .m-3 {
  margin: 24px !important;
}
#appCapsule .m-4 {
  margin: 32px !important;
}
#appCapsule .m-5 {
  margin: 40px !important;
}

#appCapsule .mt-0 {
  margin-top: 0 !important;
}
#appCapsule .mt-05 {
  margin-top: 5px !important;
}
#appCapsule .mt-1 {
  margin-top: 8px !important;
}
#appCapsule .mt-2 {
  margin-top: 16px !important;
}
#appCapsule .mt-3 {
  margin-top: 24px !important;
}
#appCapsule .mt-4 {
  margin-top: 32px !important;
}
#appCapsule .mt-5 {
  margin-top: 40px !important;
}

#appCapsule .mb-0 {
  margin-bottom: 0px !important;
}
#appCapsule .mb-05 {
  margin-bottom: 5px !important;
}
#appCapsule .mb-1 {
  margin-bottom: 8px !important;
}
#appCapsule .mb-2 {
  margin-bottom: 16px !important;
}
#appCapsule .mb-3 {
  margin-bottom: 24px !important;
}
#appCapsule .mb-4 {
  margin-bottom: 32px !important;
}
#appCapsule .mb-5 {
  margin-bottom: 40px !important;
}

#appCapsule .ms-0 {
  margin-left: 0 !important;
}
#appCapsule .ms-05 {
  margin-left: 5px !important;
}
#appCapsule .ms-1 {
  margin-left: 8px !important;
}
#appCapsule .ms-2 {
  margin-left: 16px !important;
}
#appCapsule .ms-3 {
  margin-left: 24px !important;
}
#appCapsule .ms-4 {
  margin-left: 32px !important;
}
.ms-5 {
  margin-left: 40px !important;
}
.ms-6 {
  margin-left: 50px !important;
}

.me-0 {
  margin-right: 0px !important;
}
.me-05 {
  margin-right: 5px !important;
}
.me-1 {
  margin-right: 8px !important;
}
.me-2 {
  margin-right: 16px !important;
}
.me-3 {
  margin-right: 24px !important;
}
.me-4 {
  margin-right: 32px !important;
}
.me-5 {
  margin-right: 40px !important;
}

.p-0 {
  padding: 0 !important;
}
.p-05 {
  padding: 5px !important;
}
.p-1 {
  padding: 8px !important;
}
.p-2 {
  padding: 16px !important;
}
.p-3 {
  padding: 24px !important;
}
.p-4 {
  padding: 32px !important;
}
.p-5 {
  padding: 40px !important;
}

.pt-0 {
  padding-top: 0px !important;
}
.pt-05 {
  padding-top: 5px !important;
}
.pt-1 {
  padding-top: 8px !important;
}
#appCapsule .pt-2,
#sidebarPanel .pt-2 {
  padding-top: 16px !important;
}
.pt-3 {
  padding-top: 24px !important;
}
.pt-4 {
  padding-top: 32px !important;
}
.pt-5 {
  padding-top: 40px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}
.pb-05 {
  padding-bottom: 5px !important;
}
.pb-1 {
  padding-bottom: 8px !important;
}
#appCapsule .pb-2,
#sidebarPanel .pb-2 {
  padding-bottom: 16px !important;
}
.pb-3 {
  padding-bottom: 24px !important;
}
.pb-4 {
  padding-bottom: 32px !important;
}
.pb-5 {
  padding-bottom: 40px !important;
}

.ps-0 {
  padding-left: 0px !important;
}
.ps-05 {
  padding-left: 5px !important;
}
.ps-1 {
  padding-left: 8px !important;
}
.ps-2 {
  padding-left: 16px !important;
}
.ps-3 {
  padding-left: 24px !important;
}
.ps-4 {
  padding-left: 32px !important;
}
.ps-5 {
  padding-left: 40px !important;
}

.pe-0 {
  padding-right: 0px !important;
}
.pe-05 {
  padding-right: 5px !important;
}
.pe-1 {
  padding-right: 8px !important;
}
.pe-2 {
  padding-right: 16px !important;
}
.pe-3 {
  padding-right: 24px !important;
}
.pe-4 {
  padding-right: 32px !important;
}
.pe-5 {
  padding-right: 40px !important;
}
