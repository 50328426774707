.otp-confirmation {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .1)), url('../../../../assets/img/otp-confirmation-background.png') no-repeat center 15%/cover;
  height: 100vh;
  padding-top: 45px;

  > div {
    margin-left: 90px;
  }

  .otp-form {
    background: white;
    border-radius: 24px;
    width: 30%;
    margin-left: 20px;
    padding: 30px 0;

    > div {
      width: 80%;
      margin: 0 auto;
    }

    form {
      label {
        width: 100%;
      }

      .form-control {
        outline: none;
        border-radius: 10px;
        width: 100%;
        font-size: .9rem;
      }

      .sign-in-cta {
        button {
          border-radius: 20px !important;
          padding: 5px 0 !important;
        }
      }
    }
  }
}