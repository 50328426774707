$positioning: (
        '10px': 10px,
        '20px': 20px,
        '30px': 30px,
        '40px': 40px,
        '50px': 50px,
        '57px': 57px,
        '60px': 60px,
        '100px': 100px,
        '125px': 125px,
        '130px': 130px,
        '135px': 135px,
        '140px': 140px,
        '145px': 145px,
        '150px': 150px,
        '200px': 200px,
        'inherit': inherit
);


@each $name, $value in $positioning {
  .left-#{$name} {
    left: $value !important;
  }

  .right-#{$name} {
    right: $value !important;
  }

  .top-#{$name} {
    top: $value !important;
  }

  .bottom-#{$name} {
    bottom: $value !important;
  }
}
