////////////////////////////////////////////////////////////////
// Bootstrap 5
////////////////////////////////////////////////////////////////
//@import "node_modules/bootstrap/scss/bootstrap";

////////////////////////////////////////////////////////////////
// Plugins
////////////////////////////////////////////////////////////////
@import url('~@splidejs/splide/dist/css/splide.min.css');
@import url('~react-date-range/dist/styles.css');
@import url('~react-date-range/dist/theme/default.css') ;

// Spaces Settings
////////////////////////////////////////////////////////////////
// Font Settings
@import 'src/layout/assets/sass/font';
// Spaces Variables
@import 'src/layout/assets/sass/variables';

////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////
// Body - General Settings
@import 'src/layout/assets/sass/body';
// Header
@import 'src/layout/assets/sass/layout/header';

// Content
@import 'src/layout/assets/sass/layout/content';
// Sidebar
@import 'src/layout/assets/sass/layout/sidebar';
// Footer
@import 'src/layout/assets/sass/layout/footer';

// FAQ Page
@import 'src/layout/assets/sass/layout/faq';

// Package Signup Page
@import 'src/layout/assets/sass/layout/package-signup';

// OTP Confirmation Page
@import 'src/layout/assets/sass/layout/otp-confirmation';

// Package Selection Page
@import 'src/layout/assets/sass/layout/package-select';

// Membership Plan Page
@import 'src/layout/assets/sass/layout/membership-plan';

// Package Order Summary Page
@import 'src/layout/assets/sass/layout/package-order-summary';

// Pricing Page
@import 'src/layout/assets/sass/layout/pricing-page';

////////////////////////////////////////////////////////////////
// Blocks
////////////////////////////////////////////////////////////////
@import 'src/layout/assets/sass/blocks';

////////////////////////////////////////////////////////////////
// RTL - Right to Left Stylesheet
////////////////////////////////////////////////////////////////
@import 'src/layout/assets/sass/rtl';

////////////////////////////////////////////////////////////////
// Dark Mode
////////////////////////////////////////////////////////////////
@import 'src/layout/assets/sass/darkmode';

////////////////////////////////////////////////////////////////
// UI
////////////////////////////////////////////////////////////////
@import 'src/layout/assets/sass/ui';


#appCapsule {
  font-family: $fontFamily !important;
  font-size: $fontSize !important;
  line-height: $bodyLineHeight;
  letter-spacing: $bodyLetterSpacing;
  color: $colorText;
  background: $bodyBackground;
  width: 100%;
  //height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
