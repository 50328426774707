.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity-minus,
.quantity-plus {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;
  color: $colorHeading;
  text-decoration: none;
  text-align: center;
  line-height: 30px;
  border: 1px solid $formBorderColor;
  border-radius: 3px;
}
.quantity-minus:hover,
.quantity-plus:hover {
  background: $colorPrimary;
  color: white !important;
}

.quantity-input {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-right: 2px solid #ffffff;
  background: #fff;
  color: $colorHeading;
}
.quantity-minus:link,
.quantity-plus:link {
  color: #8184a1;
}
.quantity-minus:visited,
.quantity-plus:visited {
  color: $colorHeading;
}
