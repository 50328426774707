@font-face {
    font-family: "ProximaBold";
    src: local("ProximaBold"),
        url("/assets/fonts/ProximaNova-Bold.woff") format("woff");
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("/assets/fonts/ProximaNova-Regular.woff") format("woff");
}

$primary: #017bef;
$dark: #343434;
$light-primary-bg: #eaedfc;
$info: #61dafb;
$warning: #f99500;
$danger: #f85a47;
$theme-colors: (
    "primary": $primary,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
);

html,
body {
    font-family: Montserrat, serif;
    height: 100vh;
    color: black !important;
}

.mobile-view {
    //font-family: $fontFamily;
    //font-size: $fontSize;
    //height: 100%;
}

#appCapsule {
    //height: 100%;
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

.property-detail {
}

.inline-icon {
    vertical-align: bottom;
    font-size: 22px !important;
}

.rent-button {
    background-color: $light-primary-bg;
    color: $primary;
}
.detail-images-container {
    //max-height: 420px;
    //overflow-y: scroll;
}

.image-large-container {
    flex-basis: 75%;
}

.image-siblings-container {
    flex-basis: 25%;
    max-height: 480px;
    overflow-y: scroll;
}
.curved {
    //border: #f85a47 1px solid;
    border-radius: 20px;
}
::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}

.detail-images-container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.detail-images-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.detail-image-dimensions {
    border-radius: 20px;
    //height: 100% !important;
    max-height: 480px !important;
    width: 100% !important;
}

.sibling-images {
    width: 100% !important;
    border-radius: 20px;
    cursor: pointer;
    //margin-bottom: 20px;
}

.sibling-images:hover {
    //width: 100% !important;
    border: $primary 2px solid;
    padding: 8px;
}

.detail-image {
    object-fit: cover !important;
    object-position: 50% 61.8% !important;
    vertical-align: bottom;
}
.category-button {
    //color: #7D423A;
    background-color: $light-primary-bg;
    color: #017bef;
}
.price-section {
    flex-basis: 35%;
}

.rc-slider-track {
    background: $primary !important;
}

.rc-slider-handle {
    border: solid 2px #017bef !important;
}

.description-section {
    flex-basis: 65%;
}

.bg-gray {
    background-color: #e0e0e0;
}
.amenity-chip-margin {
    flex-basis: 30%;
}

// Sidebar
.sideNav {
    background-color: #343434;
    z-index: 1000;
    min-width: 14.666667%;
}

.nav-link,
.sideNav button.btn-link {
    display: block;
    color: #f4f4f4;
}

.nav-link,
.sideNav button.btn-link {
    margin: 0.4rem 0 0.4rem !important;
}

/* For tabs only */
.tab-container .nav-tabs .nav-link {
    padding: 0.8rem !important;
}

.sideNav button.btn-link[aria-expanded="true"] {
    margin: 0.4rem 0 0.4rem !important;
}

.parent-menu-item {
    padding-left: 8px !important;
}

.nav-link.top-nav-item,
.nav-item.top-nav-item a.nav-link {
    padding: 0 !important;
}

.nav-link {
    // padding: 5px 10px 4px 10px !important;
    // padding: 10px 15px;
    color: #fff; /* Default text color */
    text-decoration: none;
    transition: font-weight 0.2s;
    border-left: 6px solid transparent;
    font-size: small;
}

// .nav-link.sidebar-sub-menu-item {
//     padding: 50px 35px !important; /* Example padding */
//     color: #fff; /* Text color */
//     text-decoration: none; /* Remove underline */
//     transition: font-weight 0.2s; /* Smooth transition for font weight */
// }

.sidebar-sub-menu {
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
}

.sidebar-sub-menu .nav-link {
    padding: 10px 15px 10px 60px !important; /* Example padding */
    transition: font-weight 0.2s;
}

// .nav-link:focus,
.nav-link:hover,
button.btn-link:hover {
    color: #c5c5c5;
    text-decoration: none !important;
    // font-weight: bold; /* Bold on hover */
}

.bg {
    .nav-link:hover {
        font-weight: normal; /* Bold on hover */
    }

    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url("./assets/img/Web/Spacia/settings/payment/bookingbg.png");
    height: 70vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10;
    padding: 20px;
}

.loadingScreen {
    width: 80vw;
    height: 100vh;
    background-color: white;
    position: fixed;
    text-align: center;
    padding-top: 25%;
}

.signupBg {
    background-image: url("./assets/img/Web/Spacia/nastuh-abootalebi-eHD8Y1Znfpk-unsplash 1.png");
    height: 90vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10;
    padding: 20px;
}

.sign-in-wrapper {
    background: url("/assets/img/spacia/web/Background.png") no-repeat;
    background-size: cover;
}

input.PhoneInputInput {
    border: none;
    padding: 10;
    border-radius: 10;
}

.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    padding: 20;
    margin-top: 10;
}

.ReactTags__selected {
    line-height: 1.5;
    display: flex;
    flex-wrap: wrap;
    background-color: #ececec;
    border-radius: 5px;
    border: 1px solid #ced4da;
}
.ReactTags__tag {
    background-color: $primary;
    font-family: Montserrat;
    padding: 10px;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.ReactTags__tagInputField {
    border: none;
    margin-top: 10px;
    width: 100%;
    background-color: transparent;
}

.ReactTags__tagInput {
    width: auto;
    flex-grow: 1;
}

.ReactTags__tagInputField:focus {
    outline: none;
}

.ReactTags__remove {
    border: none;
    background-color: transparent;
    color: white;
    margin-left: 6px;
}

.sccard {
    display: inline-block;
}

.form-group input,
.form-group textarea {
    background-color: #ececec;
}

.bgImage {
    width: "100vw";
    height: "100vh";
    background-color: "green";
    position: "relative";
}

.button-yellow {
    background-color: #f99500;
    border-radius: 5px;
    padding: 10px 20px;
    border: none;
    color: white;
}

.button {
    background-color: $primary;
    border-radius: 5px;
    padding: 10px 20px;
    border: none;
    color: white;
    width: 150px;
    border: none;
}

.button-calm {
    background-color: #e2e2e2;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    border: none;
    color: black;
    width: 150px;
    height: 56px;
    margin-right: 20px;
}

.header {
    padding: 20px;
}

.stripCard {
    display: flex;
    border: 1px solid #3434344d;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.settingsNavItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.newmodal {
    height: 80vh;
    background-image: url("./assets/img/Web/Spacia/nastuh-abootalebi-eHD8Y1Znfpk-unsplash 1.png");
    background-size: cover;
}

.selectCard {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.selectCard h6 {
    margin-top: auto;
    margin-bottom: auto;
}

.bsdropdown {
    background-color: transparent;
}

.selectCard:hover {
    border: 1px solid red;
}

.backCard {
    width: 100%;
    height: 7vh;
    background-color: "white";
    padding-top: 10vh;
}

.active {
    font-weight: bold;
}

.ellipse {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #343434;
    position: relative;
}

input {
    background-color: #ececec;
}

.ListPropBtn {
    border: none;
    margin: 50px 20px;
    background-color: $primary;
    color: #fff;
    width: 130px;
    height: 45px;
    border-radius: 5px;
}
.headerListPropBtn {
    flex-basis: 50%;
    position: relative;
}
.headerListPropBtn button {
    position: absolute;
    right: 10px;
    top: 20px;
    border: none;
    border-radius: 5px;
    background-color: $primary;
    color: #fff;
    height: 45px;
    width: 180px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"] {
        overflow: hidden;
        width: 80px;
        -webkit-appearance: none;
        //background-color: #f859475e;
        background-color: $primary;
        border-radius: 10px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        height: 10px;
        -webkit-appearance: none;
        color: #13bba4;
        margin-top: -1px;
    }

    input[type="range"]::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 30px;
        cursor: ew-resize;
        background: $primary;
        box-shadow: -80px 0 0 80px #ff0e0e;
        cursor: pointer;
    }
}

/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #43e5f7;
}
input[type="range"]::-moz-range-track {
    background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7;
}
input[type="range"]::-ms-fill-upper {
    background-color: #9a905d;
}

.paymentCard {
    background-color: #f0f0f0;
    border: 1px dashed rgba(128, 128, 128, 0.63);
    padding: 20px 40px;
    padding-left: 20px;
    display: flex;
    border-radius: 5px;
    margin-right: 20px;
    color: #787878;
    /* width: 150px !important; */
}

.paymentCard-active {
    background-color: $light-primary-bg;
    border: 1px dashed $primary;
    padding: 20px 40px;
    padding-left: 20px;
    display: flex;
    border-radius: 5px;
    margin-right: 20px;
    color: $primary;
}

.flatText {
    margin-bottom: 0;
}

.user-admin {
    background-color: $primary;
}

.link {
    text-decoration: none;
}

.headerUnderlineActive {
    padding: 10px;
    border-bottom: 1px solid #343434;
}

.flexStrip {
    display: flex;
    justify-content: normal;
}

.ellipseAttribute {
    position: absolute;
    bottom: 0;
    right: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    //color: red !important;
    color: $primary !important;
}

.nav-link.active {
    font-weight: bold; /* Make the active link bold */
    color: #ffffff;
    border-left: 4px solid #006be6;
    // background-color: rgba(255, 255, 255, 0.1); /* Optional: Add a background color */
}

.nav-tabs .nav-link {
    color: #393939 !important;
}

/*tr:nth-child(odd) {
/*	background: white;*/
/*}*/

.fomr-control {
    font-size: 1rem;
}

.mr-sm-2.search {
    margin-right: 0 !important;
    width: 70% !important;
}

.strip {
    display: flex;
    justify-content: space-between;
}

.TableImg {
    background-color: #343434;
    border-radius: 50%;
    /* width: 10;
  height: 10; */
}

.uploadBox {
    padding: 20px;
    text-align: left;
    border: 1px dashed $primary;
    background-color: #ffcbc6;
}

.uploadButton {
    padding: 1px;
    border: none;
    color: white;
    background-color: #ffcbc6;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: -1px;
}
.fitImage img {
    border-radius: 6px;
}

.role-admin {
    color: red;
    background-color: red;
    border: none;
    padding: 10px;
}

.inputWrapper {
    height: 32px;
    width: 64px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    /*Using a background color, but you can use a background image to represent a button*/
    background-color: #ddf;
}
.fileInput {
    cursor: pointer;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    font-size: 50px;
    /*Opacity settings for all browsers*/
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 100%;
    margin-top: 40px;
}
.mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 100%;
}

.tableUnderline {
    border-bottom: #343434;
    padding: 10;
}

.fixedBottom {
    position: fixed;
    bottom: 5vh;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// Typography
a {
    text-decoration: none !important;
}

.line-title {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.line-title::before,
.line-title::after {
    content: "";
    flex: 1 1 auto;
    border-bottom: 0.5px dashed #848484;
}

.line-title::before {
    margin-right: 20px;
}

.line-title::after {
    margin-left: 20px;
}

.nav-link,
.sideNav button.btn-link {
    text-decoration: none;
}

.table th,
.table td {
    padding: 0.75rem !important;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}

.table th {
    border-top: unset !important;
}

.min-vh-70 {
    min-height: 75vh;
}

.settings-side .nav-link {
    color: #444444;
}
.whole-block {
    width: auto;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.whole-block a {
    padding: 11px 15px;
    margin: 8px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 30px;
}

.whole-block > li:not(.paginate-active) a {
    background: #d8d8d8;
    color: white;
}

.paginate-active {
    color: white;
}

.paginate-active a {
    background: #393939;
    color: white;
}

.previous-link {
    background: transparent !important;
    padding: 11px 15px;
    margin: 3px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    color: #0f0f0f !important;
}

.next-link {
    background: transparent !important;
    padding: 11px 15px;
    margin: 3px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    color: #0f0f0f !important;
}

.table-vtop {
    td,
    th {
        vertical-align: top;
    }

    thead,
    th {
        background: #f4f4f4;
        color: #464646;
        font-weight: 300;
    }
    tbody td {
        background: #ffffff;
    }
}
.table th {
    border: unset !important;
}

.table tr:not(:first-child) td {
    border-top: 1px dashed #dee2e6 !important;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: dashed;
    border-width: 0;
}

tr td.cropped-cell {
    width: 200px;
}

tr td.cropped-cell img {
    border-radius: 5px;
    object-fit: cover;
    object-position: top;
    max-width: 200px;
    max-height: 120px;
    min-width: 200px;
    min-height: 120px;
    display: block;
}

div[role="dialog"].modal.show {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

div.modal-dialog {
    width: 100%;
}

.calendar input#datetime-local {
    font-size: 1.11rem;
}

.manage-properties-list:hover {
    background: rgba(58, 175, 220, 0.1);
    cursor: pointer;
}

.manage-props-modal button.close > span {
    font-size: 20px;
}

.statement-modal {
    margin-top: -100px;
}

.datetime-picker input.MuiOutlinedInput-input {
    font-size: 1.21rem;
}

.datetime-picker > div.MuiFormControl-root {
    width: 55%;
}

.datetime-picker div.MuiOutlinedInput-root {
    height: 50px;
}

.year-picker-container fieldset.MuiOutlinedInput-notchedOutline {
    border-top: none;
    border-left: none;
    border-right: none;
}

.year-picker-container input.MuiOutlinedInput-input {
    padding-bottom: 8px !important;
}

@media (min-width: 576px) {
    div.manage-props-modal > .modal-dialog {
        max-width: 800px;
        margin: 1.75rem auto;
    }

    div.membership-plan-modal .modal-dialog {
        max-width: 70%;
        margin: 1.75rem auto;
    }

    div.statement-modal > .modal-dialog {
        max-width: 600px;
    }
}

.notifications-popover {
    min-width: 320px;
    max-width: 400px;
}

.notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
}

.notification-bell-wrapper {
    position: relative;
    cursor: pointer;
    padding: 8px;

    .notification-bell {
        font-size: 1.25rem;
        color: #6c757d;
    }

    .notification-indicator {
        position: absolute;
        top: 4px;
        right: 2px;
        width: 8px;
        height: 8px;
        background-color: #017bef;
        border-radius: 50%;
    }
}

.notification-item {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f8f9fa;
    }

    &.unread {
        background-color: #f0f7ff;
    }
}

.notification-icon-wrapper {
    position: relative;
    margin-right: 1rem;

    .notification-icon {
        font-size: 1.5rem;
    }

    .unread-dot {
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        height: 8px;
        background-color: #017bef;
        border-radius: 50%;
        transform: translate(25%, -25%);
    }
}

.notification-content {
    flex: 1;

    .notification-title {
        margin-bottom: 0.25rem;
        font-size: 0.9rem;
        font-weight: 500;
    }

    .notification-message {
        margin-bottom: 0.25rem;
        font-size: 0.85rem;
        color: #6c757d;
    }

    .notification-time {
        color: #adb5bd;
    }
}

.nav-tabs {
    display: flex;
    padding: 0 1rem;
    border-bottom: 1px solid #e9ecef;

    .tab-button {
        background: none;
        border: none;
        color: #6c757d;
        padding: 0.5rem 1rem;
        cursor: pointer;
        position: relative;

        &.active {
            color: #017bef;

            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #017bef;
            }
        }

        &:hover {
            color: #017bef;
        }
    }
}

.tab-content {
    min-height: calc(100vh - 200px); // Account for header and tabs
    height: auto;
    overflow-y: auto;
    padding-bottom: 2rem;
}

.notification-tabs {
    display: flex;
    padding: 0 1rem;
    border-bottom: 1px solid #e9ecef;

    .tab-button {
        flex: 1;
        background: none;
        border: none;
        color: #6c757d;
        padding: 0.75rem 0.5rem;
        cursor: pointer;
        position: relative;
        font-size: 0.85rem;

        &.active {
            color: #017bef;
            font-weight: 500;

            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #017bef;
            }
        }

        &:hover {
            color: #017bef;
        }
    }
}

.notification-content-wrapper {
    max-height: 400px;
    overflow-y: auto;
}

.notifications-dropdown {
    min-width: 400px !important;

    .dropdown-menu {
        min-width: 400px !important;
        max-width: 400px !important;
        padding: 0;
        margin-top: 0.5rem !important;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .dropdown-toggle {
        padding: 0 !important;

        &::after {
            display: none !important;
        }
    }
}

.notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e9ecef;

    h6 {
        font-size: 0.9rem;
        margin: 0;
    }

    button {
        font-size: 0.8rem;
    }
}

.notification-item {
    display: flex;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e9ecef;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f8f9fa;
    }

    &.unread {
        background-color: #f0f7ff;
    }
}

.notification-content {
    flex: 1;
    min-width: 0; // Helps with text truncation
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.notification-title {
    font-size: 0.85rem !important;
    font-weight: 500;
    margin: 0;
    flex: 1;
    min-width: 0;
    max-width: 70%;
}

.notification-time {
    font-size: 0.75rem;
    color: #adb5bd;
    white-space: nowrap;
}

.notification-message {
    font-size: 0.8rem;
    color: #6c757d;
    margin: 0;
    line-height: 1.4;
    max-height: 2.8em; // Limits to 2 lines
    overflow: hidden;
}

.read-more-button {
    font-size: 0.75rem;
    padding: 0;
    margin-left: 4px;
}

.notification-tabs {
    .tab-button {
        font-size: 0.85rem;
        padding: 0.5rem 0.75rem;
    }
}

.notification-icon-wrapper {
    margin-right: 0.75rem;

    .notification-icon {
        font-size: 1.25rem;
    }
}

.mark-all-read-btn {
    background: none;
    border: none;
    padding: 4px;
    color: #017bef;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s;

    &:hover {
        background-color: rgba(1, 123, 239, 0.1);
    }
}

.notification-tabs {
    display: flex;
    padding: 0 1rem;
    border-bottom: 1px solid #e9ecef;

    .tab-button {
        flex: 1;
        background: none;
        border: none;
        color: #6c757d;
        padding: 0.75rem 0.5rem;
        cursor: pointer;
        position: relative;
        font-size: 0.85rem;

        &.active {
            color: #017bef;
            font-weight: 500;

            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #017bef;
            }
        }

        &:hover {
            color: #017bef;
        }
    }
}

.notifications-header {
    h6 {
        font-weight: 500;
    }
}

// iframe {
//     display: none;
// }
