////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////
$black: #000000;

// Body Background
$bodyBackground : #ffffff;

// Primary Colors
$colorPrimary : #017BEF;
$colorPrimaryShade :rgba(1, 123, 239, 0.5);
$colorSecondary : #F4F6F8;
$colorSuccess: #1DCC70;
$colorGreen: #02BD04;
$colorDanger : #FF396F;
$colorWarning : #FFB400;
$colorInfo : #05D0A4;

// Line Colors
$colorLine : #DCDCE9;

// Text Colors
$colorHeading : #27173E;
$colorText : rgb(149, 141, 158);
$colorLight : #A9ABAD;

// Border Radius
$borderRadius: 10px;

// Inputs
$formBorderColor: #CED4DA;
// Safearea
$safeBottom : env(safe-area-inset-bottom);
$safeTop : env(safe-area-inset-top);


////////////////////////////////////////////////////
// Dark Mode Colors
////////////////////////////////////////////////////
$darkmode_bodyBackground: #030108;
$darkmode_contentBackground: #161129;
$darkmode_colorHeading: #fff;
$darkmode_colorText: #8f82a5;
$darkmode_colorLight: #69587f;
$darkmode_colorLine: #2d1f3b;


////////////////////////////////////////////////////
// Font Sizes
////////////////////////////////////////////////////
// You can change font family in _font.scss
$fontSizeHeadingXLarge: 32px;
$fontSizeHeadingLarge: 20px;
$fontSizeHeading: 17px;
$fontSize: 15px;
$fontSizeSub: 13px;
$fontSizeSmall: 12px;
$fontSizeCaption: 11px;
$fontSizeLabel: 14px;

$bodyLineHeight: 1.6rem;
$bodyLetterSpacing: 0.004em;

// accordion variables
$accordion-icon-color:                    $black;
$accordion-icon-active-color:             $black;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$accordion-icon-color}' class='bi bi-chevron-right' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

