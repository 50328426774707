.faqs-mobile {
    padding: 1rem;

    .faq-section {
        margin-bottom: 2rem;

        h2 {
            font-size: 1.5rem;
            color: #1f2937;
            margin-bottom: 1.5rem;
        }

        .section-title {
            font-size: 1.2rem;
            color: #1f2937;
            margin-bottom: 1rem;
            font-weight: 500;
        }

        .MuiAccordion-root {
            margin-bottom: 1rem;
            border-radius: 0.5rem;
            box-shadow: none;
            border: 1px solid #e5e7eb;

            &::before {
                display: none;
            }

            .MuiAccordionSummary-root {
                padding: 1rem;
                color: #1f2937;
                font-weight: 500;
            }

            .MuiAccordionDetails-root {
                padding: 1rem;
                color: #4b5563;
            }
        }
    }

    .hero-section {
        text-align: center;
        padding: 2rem 1rem;
        background: linear-gradient(135deg, #198df9 0%, #017bef 100%);
        color: white;
        border-radius: 1rem;
        margin-bottom: 2rem;

        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            opacity: 0.9;
        }
    }
}
