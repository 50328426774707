#appCapsule h1,
#appCapsule h2,
#appCapsule h3,
#appCapsule h4,
#appCapsule h5,
#appCapsule h6 {
    color: $colorHeading;
    margin: 0 0 10px 0;
    small {
        color: rgba($colorHeading, 0.6);
    }
    .badge {
        line-height: 1em;
    }
}
#appCapsule .text-muted {
    font-size: $fontSizeSub;
    color: $colorText !important;
}
#appCapsule h1 {
    font-size: $fontSizeHeadingXLarge;
    font-weight: $bold;
}
#appCapsule h2 {
    font-size: $fontSizeHeadingLarge;
    font-weight: $bold;
}
#appCapsule h3 {
    font-size: $fontSizeHeading;
    font-weight: $bold;
}
#appCapsule h4 {
    font-size: $fontSize;
    font-weight: $medium;
}
#appCapsule h5 {
    font-size: $fontSizeSub;
    font-weight: $medium;
}
#appCapsule h6 {
    font-size: $fontSizeCaption;
    font-weight: $medium;
}
#appCapsule .lead {
    font-weight: $regular;
    font-size: $fontSizeHeading;
    color: $colorText;
}
#appCapsule mark {
    border-radius: $borderRadius;
}
#appCapsule .text-large {
    font-size: $fontSizeHeadingXLarge;
}

#appCapsule strong,
#appCapsule b {
    font-weight: $medium;
}

#appCapsule .fontsize-normal {
    font-size: $fontSize !important;
}
#appCapsule .fontsize-sub {
    font-size: $fontSizeSub !important;
}
#appCapsule .fontsize-small {
    font-size: $fontSizeSmall !important;
}
#appCapsule .fontsize-caption {
    font-size: $fontSizeCaption !important;
}
#appCapsule .fontsize-heading {
    font-size: $fontSizeHeading !important;
}
#appCapsule .fontsize-headingLarge {
    font-size: $fontSizeHeadingLarge !important;
}
#appCapsule .fontsize-headingXLarge {
    font-size: $fontSizeHeadingXLarge !important;
}
