.pricing-card {
  .blue-card {
    width: 80%;
    height: 500px;
    background: #4E2D92;
    box-shadow: 0 22px 24px rgba(78, 45, 146, 0.433676);
    border-radius: 20px;

    .header.price {
      * {
        display: inline-block;
        color: white;
      }
    }
  }
}