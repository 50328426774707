.pkg-select {
  header:not(.main-section-header) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .1)), url('../../../../assets/img/pkg-select-background.png') no-repeat center 15%/cover;
    padding-bottom: 150px;
  }

  div.gradient {
    background: url('../../../../assets/img/pkg-signup-gradient.png') no-repeat center 15%/cover;
    height: 15px;
  }

  section.main-section {
    width: 50%;
    margin: 0 auto;
  }

  .bottom-image-frame {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .1)), url('../../../../assets/img/png/lady-with-laptop.png') no-repeat center 15%/cover;
    height: 400px;
    margin-top: 100px;
  }
}