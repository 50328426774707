@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.main_landing_wrapper {
	font-family: "Montserrat", sans-serif;
}

.landingpage_hero_wrapper {
	position: relative;
	height: 100vh;
	width: 100%;
	background: url("/src/assets/img/smiling-woman.svg");
	background-repeat: no-repeat;
	background-size: cover;
}

.header_icon_route:hover {
	cursor: pointer;
}

.landingpage_header {
	display: flex;
	align-items: center;
	margin-left: 50px;
}

.landingpage_header>div {
	display: flex;
	margin-left: 20px;
}

.landingpage_header>div>p {
	margin-left: 20px;
	font-size: 14px;
	font-weight: 600;
}

.landing_text_area {
	margin-top: 40px;
	margin-left: 80px;
	width: 40%;
}

.landing_text_area>h2 {
	font-size: 30px;
}

.landing_text_area>h1 {
	font-size: 80px;
	font-weight: 600;
	line-height: 1.2;
	color: #000;
}

.landing_text_area>p {
	font-size: 18px;
	font-weight: 300;
	line-height: 1.7;
	color: #000;
}

.absolute_image {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	z-index: -1;
}

.gradient_image_div {
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background: linear-gradient(91.52deg,
			#be6be5 36.53%,
			#e8ea78 77.24%,
			#e3ffcd 106.27%);
}

.landingpage_footer_area {
	font-family: "Poppins", sans-serif;
}

.footer_interstyle {
	font-family: "Inter", sans-serif;
}

.enterprise_your_business {
	background: linear-gradient(0deg,
			rgba(38, 38, 38, 0.64),
			rgba(38, 38, 38, 0.64)),
		url("../../../assets/img/spacia/close-up-african.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0px 2.86773px 7.16931px rgba(18, 17, 39, 0.1);
	border-radius: 20px 20px 0px 0px;
	font-family: "Montserrat", sans-serif;
}

.bold_heading {
	margin-bottom: "20px";
	font-weight: 500;
	font-size: "50px";

}

/* .work_stay_play_p {
	font-size: "41px";
	width: "70%";
	font-weight: 400;
	line-height: "43px";
	color: "#263238";
} */

.active-link {
	/* Define the styles for active links here */
	font-weight: bold;
	color: #be6be5;
	text-decoration: underline;
	/* Add any other styles you want */
}