.membership-plan {
  > div {
    width: 80%;
    margin: 0 auto;
  }

  > h3 {
    width: 80%;
    margin: 0 auto;
  }

  button.confirm-order {
    padding: 10px 10px;
    background: #017BEF;
    border-radius: 96px;
    font-size: .79rem;
  }
}