.membership-plan-card {
  width: 30%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-shadow: 0 10px 20px rgba(0, 40, 100, 0.25);
  border-radius: 16px;
  padding: 30px;

  > header {
    text-align: center;

    .title {
      font-size: 1rem;
    }

    .cost {
      font-style: normal;
      font-weight: 700;
      color: #170F49;
      font-size: 1.589rem;
    }
  }

  .blue-bullet {
    width: 7px;
  }

  section.package-details {
     p {
      font-size: .89rem;
    }

    figure {
      margin-right: 7px;
    }
  }

  section.no-of-users {
    pointer-events: none;
    > p {
      font-size: .89rem;
      color: #6F6C90;
    }
  }

  button {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    font-size: .79rem;
  }
}

.membership-plan-card.selected {
  border: 1px solid rgba(18, 17, 245, 0.87);
  section.no-of-users {
    pointer-events: all;
  }
}