.corporate-page-mobile {
    padding: 1rem;

    .hero-section {
        text-align: center;
        padding: 2rem 1rem;

        .logo {
            width: 120px;
            height: auto;
            margin-bottom: 24px;
        }
        background: linear-gradient(135deg, #198df9 0%, #017bef 100%);
        color: white;
        border-radius: 1rem;
        margin-bottom: 2rem;

        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            opacity: 0.9;
        }

        .watch-video-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            background: rgba(255, 255, 255, 0.2);
            border: none;
            color: white;
            padding: 0.75rem 1.5rem;
            border-radius: 2rem;
            font-size: 1rem;
            margin: 0 auto;

            &:active {
                transform: scale(0.98);
            }
        }
    }

    .packages-section {
        margin-bottom: 2rem;

        .view-toggle {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-bottom: 2rem;

            button {
                padding: 0.75rem 1.5rem;
                border: 1px solid #e5e7eb;
                background: white;
                border-radius: 2rem;
                font-size: 1rem;
                color: #4b5563;

                &.active {
                    background: #017bef;
                    color: white;
                    border-color: #017bef;
                }
            }
        }
    }

    .packages-container {
        .package-card {
            background: white;
            border-radius: 1rem;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
            padding: 1.5rem;
            margin-bottom: 1.5rem;

            .package-header {
                margin-bottom: 1.5rem;

                h3 {
                    font-size: 1.5rem;
                    color: #1f2937;
                    margin-bottom: 1rem;
                }

                .price {
                    font-size: 2rem;
                    color: #017bef;
                    display: flex;
                    align-items: baseline;
                    gap: 0.25rem;

                    .currency {
                        font-size: 1.25rem;
                    }

                    .period {
                        font-size: 1rem;
                        color: #6b7280;
                    }
                }
            }

            .package-content {
                p {
                    color: #4b5563;
                    margin-bottom: 1.5rem;
                }

                .get-started-btn {
                    display: block;
                    width: 100%;
                    text-align: center;
                    background: #017bef;
                    color: white;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    text-decoration: none;
                    font-weight: 500;
                    margin-top: 1.5rem;

                    &:active {
                        transform: scale(0.98);
                    }
                }
            }
        }
    }

    .features-container {
        .feature-item {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            margin-bottom: 1rem;
            color: #4b5563;

            .check-icon {
                color: #017bef;
                font-size: 1.25rem;
            }
        }
    }

    .faq-section {
        h2 {
            font-size: 1.5rem;
            color: #1f2937;
            margin-bottom: 1.5rem;
            text-align: center;
        }

        .MuiAccordion-root {
            margin-bottom: 1rem;
            border-radius: 0.5rem;
            box-shadow: none;
            border: 1px solid #e5e7eb;

            &::before {
                display: none;
            }

            .MuiAccordionSummary-root {
                padding: 1rem;
                color: #1f2937;
                font-weight: 500;
            }

            .MuiAccordionDetails-root {
                padding: 1rem;
                color: #4b5563;
            }
        }
    }

    .video-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        .modal-content {
            width: 90%;
            max-width: 600px;
            background: white;
            border-radius: 1rem;
            position: relative;

            .close-btn {
                position: absolute;
                top: -2.5rem;
                right: 0;
                background: none;
                border: none;
                color: white;
                font-size: 2rem;
                padding: 0.5rem;
            }

            .video-container {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
                border-radius: 1rem;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .loading {
        text-align: center;
        color: #4b5563;
        padding: 2rem;
    }

    .error {
        text-align: center;
        color: #ef4444;
        padding: 2rem;
    }
}
