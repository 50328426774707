.pkg-order-summary {
  min-height: 100vh;
  background: #ffffff;

  .card {
    background: #ffffff;
    border: none;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.05);

    .package-details {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1.5rem 0;

      &:last-child {
        border-bottom: none;
      }

      h4 {
        color: #3699FF;
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }

      .features {
        margin-top: 1.5rem;

        h5 {
          font-size: 1rem;
          color: #464E5F;
        }

        i {
          color: #1BC5BD;
        }
      }
    }

    .order-total {
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 2px solid rgba(0, 0, 0, 0.1);

      strong {
        font-size: 1.1rem;
        
        &.text-primary {
          color: #3699FF;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .card-body {
      padding: 1.25rem !important;
    }
  }
}
// }