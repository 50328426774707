.host-landing-mobile {
  padding: 1rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2D3436;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  // Hero Section
  .hero-section {
    text-align: center;
    padding: 2rem 1rem;
    background: linear-gradient(135deg, #198df9 0%, #017bef 100%);
    color: white;
    border-radius: 1rem;
    margin-bottom: 2rem;

    .logo {
      width: 120px;
      height: auto;
      margin-bottom: 1.5rem;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
      opacity: 0.9;
      line-height: 1.5;
    }
  }

  // Features Section
  .features-section {
    margin-bottom: 2rem;

    .features-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0 0.5rem;
    }

    .feature-card {
      background: white;
      padding: 1.5rem;
      border-radius: 1rem;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      text-align: center;

      .icon-wrapper {
        width: 48px;
        height: 48px;
        margin: 0 auto 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(1, 123, 239, 0.1);
        border-radius: 12px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      h3 {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: #2D3436;
      }

      p {
        font-size: 0.875rem;
        color: #666;
        line-height: 1.4;
      }
    }
  }

  // How It Works Section
  .how-it-works-section {
    margin-bottom: 2rem;
    padding: 0 0.5rem;

    .steps {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .step {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      .step-number {
        width: 32px;
        height: 32px;
        background: #017bef;
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        flex-shrink: 0;
      }

      .step-content {
        h3 {
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
          color: #2D3436;
        }

        p {
          font-size: 0.875rem;
          color: #666;
          line-height: 1.4;
        }
      }
    }
  }

  // Tools Section
  .tools-section {
    margin-bottom: 2rem;
    padding: 0 0.5rem;

    .tools-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    .tool-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      .tool-icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;

        &[data-color="blue"] { background: rgba(1, 123, 239, 0.1); }
        &[data-color="green"] { background: rgba(39, 174, 96, 0.1); }
        &[data-color="orange"] { background: rgba(242, 153, 74, 0.1); }
        &[data-color="purple"] { background: rgba(155, 89, 182, 0.1); }

        img {
          width: 24px;
          height: 24px;
        }
      }

      span {
        font-size: 0.875rem;
        color: #2D3436;
        text-align: center;
      }
    }
  }

  // CTA Section
  .cta-section {
    text-align: center;
    padding: 2rem 1rem;
    background: linear-gradient(135deg, #198df9 0%, #017bef 100%);
    color: white;
    border-radius: 1rem;
    margin-bottom: 1rem;

    h2 {
      color: white;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
      opacity: 0.9;
    }
  }

  // Common CTA Button Style
  .cta-button {
    display: inline-block;
    background: white;
    color: #017bef;
    padding: 0.75rem 2rem;
    border-radius: 2rem;
    font-weight: 600;
    text-decoration: none;
    transition: transform 0.2s;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &:active {
      transform: scale(0.98);
    }
  }
}
