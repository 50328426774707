#appCapsule .background-size-position {
    background-size: cover !important;
    //background: linear-gradient(white ,left ,right);
    background-blend-mode: overlay;
    //background-color: black;
}
#appCapsule .form--background-grey {
    background: #f9f9f9;
}
#appCapsule .form-label-contact {
    //s
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    //line-height: 43px!important;

    /* identical to box height, or 200% */
    letter-spacing: 0.2px;

    /* second-text-color */
    color: #737373 !important;
}
#appCapsule .form-label-email {
    color: #5d5d5d !important;
}

#appCapsule .text-card {
    width: 575px;
    height: 374px;
    @media only screen and (max-width: 1000px) {
        //margin-inline: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: auto;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        img {
            display: flex;
            flex-direction: column;
            width: 234px;
            margin-inline: auto;
            object-fit: cover;
            object-position: center;
            padding-top: 0.8rem;
        }
    }
    background: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 170%;
        align-self: start;
        color: #252525;
        @media only screen and (max-width: 1000px) {
            font-size: 28px;
            line-height: 170%;
        }
        @media only screen and (max-width: 500px) {
            font-family: "Poppins", serif;
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 170%;
        }
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 43px;
        /* or 205% */
        letter-spacing: 0.8px;
        color: #252b42;
        @media only screen and (max-width: 400px) {
            //font-family: 'Montserrat',serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 43px;
            letter-spacing: 0.8px;
            color: #252b42;
        }
    }
    span {
        font-style: normal;
        font-weight: 600;
    }
    span[data-color="green"] {
        color: #08d3bb;
    }
    span[data-color="blue"] {
        color: #1090cb;
    }
    span[data-color="purple"] {
        color: #9208d3;
    }
}
#appCapsule .landing-desktop {
    padding-inline: 2rem;
    padding: 0 2rem;
}
#appCapsule .vectors-overlay-container {
    position: absolute;
    z-index: 3;
    width: 100%;
    display: flex;
    flex-grow: 1;
    div {
        position: relative;
        display: flex;
        flex-grow: 1;
        width: 100%;
        & .un {
            position: absolute;
            @media only screen and (min-width: 1000px) {
                top: 73px;
                left: 0.5vw;
            }

            top: 200px;
            left: 0.5vw;
        }
        & .deux {
            position: absolute;
            @media only screen and (min-width: 1000px) {
                top: 686px;
                left: 44vw;
            }

            top: 921px;
            left: 80vw;
        }
        & .throis {
            position: absolute;
            @media only screen and (min-width: 1000px) {
                top: 1125px;
                left: 1vw;
            }

            top: 1688px;
            left: -1vw;
        }
        & .quatre {
            position: absolute;
            // object-fit: f;
            // object-position: center;

            top: 194px;
            left: -8vw;
            bottom: 0;
            right: 0;
            height: 1523px;
            width: 98vw;
            transform: rotateY(24deg);
            @media only screen and (min-width: 1000px) {
                //top: 104px;
                //left: -16px;
                bottom: 0;
                right: 0;
                width: 674px;
                height: 1025px;
                transform: unset;
            }
        }
    }
}
#appCapsule .quatre {
    position: absolute;
    // object-fit: f;
    // object-position: center;
    top: 194px;
    left: -8vw;
    bottom: 0;
    right: 0;
    height: 1523px;
    width: 98vw;
    transform: rotateY(24deg);
    @media only screen and (min-width: 1000px) {
        //top: 104px;
        //left: -16px;
        top: 100px;
        bottom: 0;
        left: -65px;
        width: 50vw;
        height: 1053px;
        //transform:scaleY(1.1);
    }
}
#appCapsule .responsive-banner {
    height: 90vh;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    @media only screen and (max-width: 1000px) {
        height: 88vh;
        background-size: cover !important;
        background-position: left bottom !important;
    }
    padding-top: 4vh;
}
#appCapsule .hero-content {
    display: flex;
    flex-direction: column;
    width: calc(min(524px, 62vw));
    @media only screen and (max-width: 1000px) {
        margin-left: auto;
        margin-right: auto;
    }
    //@media only screen and (min-width:500px){
    //
    //}
    margin: auto auto auto calc(min(74px, 2vw));
    @media only screen and (max-width: 1000px) {
        margin-left: auto;
        margin-right: auto;
    }
    @media only screen and (max-width: 500px) {
        margin-left: auto;
        margin-right: auto;
        //width: 343px;
        width: 90%;
        height: 551px;
    }
    background: rgba(255, 255, 255, 0.72);
    backdrop-filter: blur(31px);
    border-radius: 10px;
    padding: 0 32px;
    p {
        margin-top: 17px;
        margin-bottom: 73px;
        width: 90%;
        font-style: normal;
        font-weight: normal;
        font-size: calc(min(21px, 3vw));
        line-height: 41px;
        color: #252b42;
        @media only screen and (max-width: 500px) {
            font-style: normal;
            font-weight: normal;
            //font-family: Montserrat, serif;
            font-size: 18px;
            line-height: 38px;
            color: #252b42;
            height: auto;
            letter-spacing: 0.3px;
        }
    }
    h2 {
        color: #3a3a3a;
        font-size: calc(min(40px, 4vw));
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-top: 54px;
        width: 90%;
        @media only screen and (max-width: 500px) {
            font-size: 31px;
        }
    }
}
#appCapsule .image-control {
    width: 509px;
    height: 519px;
}
#appCapsule .landing-image-grid {
    display: grid;
    grid-template-areas:
        "A B"
        "A C";
    grid-gap: 20px;
    img {
        object-fit: cover;
        object-position: center;
    }
}
#appCapsule .fs-10 {
    font-size: 10px !important;
}
#appCapsule .pc-column {
    h2 {
        //font-family: 'Poppins',  serif !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 40px !important;
        line-height: 120% !important;
        color: #3a3a3a !important;
    }
    p {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 21px !important;
        line-height: 43px !important;

        /* or 205% */
        text-align: justify !important;
        letter-spacing: 0.8px !important;

        color: #263238 !important;
    }
    button {
        width: 171.48px !important;
        height: 47.23px !important;
        background: #017bef !important;
        border: 4px solid #017bef !important;
        box-sizing: border-box !important;
        border-radius: 300px !important;
        margin-top: auto !important;
        span {
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 150% !important;
            text-align: center !important;
            color: #ffffff !important;
        }
    }
}
#appCapsule .mobile-column {
    margin-top: 117px;
    @media only screen and (max-width: 767px) {
        img {
            object-fit: cover;
            object-position: center;
        }
        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 40px !important;
            line-height: 120% !important;
            text-align: center;
            color: #3a3a3a !important;
        }
        p {
            font-weight: 400;
            width: 312.12px;
            font-size: 18px;
            line-height: 43px;
            text-align: justify;
            letter-spacing: 0.8px;
            color: #263238;
            margin-bottom: 10px;
        }
        button {
            width: 162.47px !important;
            height: 57px !important;
            background: #017bef !important;
            border: 4px solid #017bef !important;
            box-sizing: border-box !important;
            border-radius: 300px !important;
            span {
                font-style: normal !important;
                font-weight: 600 !important;
                font-size: 14px !important;
                line-height: 150% !important;
                text-align: center !important;
                color: #ffffff !important;
            }
        }
    }
}
#appCapsule .ls-text-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-inline: 3rem;
    padding: 0 3rem;

    button {
        width: 171.48px;
        height: 47.23px;
        border-radius: 25px;
    }
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }
    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 120%;
        color: #3a3a3a;
        @media only screen and (max-width: 1000px) {
            text-align: center;
        }
    }
    p {
        width: 401.99px;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 43px;
        text-align: justify;
        letter-spacing: 0.8px;
        color: #263238;
    }
}

#appCapsule .link {
    transition: all 0.1s;
    :hover {
        color: var(--bs-primary);
    }
    :active {
        transform: scale(0.9);
        transition: all 0.1s;
    }
}
.button {
    :hover {
        transition: all 0.1s;
        transform: scale(1.01);
    }
    :active {
        transform: scale(0.9);
        transition: all 0.1s;
    }
}
#appCapsule .box-shadow {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
}
.card-list {
    counter-reset: item;
    list-style-type: none;
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    li:before {
        content: counter(item) "  ";
        counter-increment: item;
        color: #00a3ff;
        width: 49px;
        @media only screen and (max-width: 400px) {
            font-size: 53px;
            left: -20px;
            width: unset;
        }
        font-size: 106px;
        position: relative;
        left: -46px;
    }
}
.image-up {
    @media only screen and (min-width: 1000px) {
        top: (-157px);
    }
}
.image-grid {
    overflow: hidden;
    margin-inline: auto;
    //max-width:100vw;
    width: calc(min(100vw, 603px));
    @media only screen and (min-width: 500px) {
        width: 100%;
    }
    //max-width: 100vw;
    display: grid;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: center;
    //align-items: baseline;
    //justify-content: center;
    //grid-template-areas: "A B B"
    //                      "A C D"
    //                      "E F D"
    grid-template-columns: auto auto auto;
    grid-column-gap: 15.29px;
    grid-template-rows: auto;
    //transform: translateX(-120px);
    img {
        border-radius: 10px;
        margin-inline: 8px;
        margin-top: 16px;
    }
}
#appCapsule .monserrat-font {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height, or 150% */
    letter-spacing: 0.1875px;
    display: flex;
    flex-direction: row;
    /* Black */
    color: #2d3436;
}
#appCapsule .font-family-monserrat {
    font-family: "Montserrat", serif;
}
#appCapsule .font-family-segoe {
    font-family: "Segoe UI", serif;
}
#appCapsule .font-family-inter {
    font-family: "Inter", serif;
}
#appCapsule .font-family-poppins {
    font-family: "Poppins", serif;
}
.top-bar {
    width: 54px;
    height: 5px;
    background: #4628a4;
}
#appCapsule .phone-wrapper {
    & .react-tel-input {
        & .form-control {
            width: calc(min(calc(464px), 34vw));
            background: white;
            padding-left: 38px;
            border-radius: 100px;
        }
        & .selected-flag {
            border-bottom-left-radius: 100px;
            border-top-left-radius: 100px;
        }
        & .flag-dropdown {
            border: none;
            border-bottom-left-radius: 100px;
            border-top-left-radius: 100px;
        }
    }
    div {
        border-bottom-left-radius: 100px;
        border-top-left-radius: 100px;
    }
}
#appCapsule .tile {
    width: 91px;
    @media only screen and (max-width: 400px) {
        width: 53px;
    }
    aspect-ratio: 1;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#appCapsule .tile[data-color="red"] {
    background-color: #fff2f2;
}
#appCapsule .tile[data-color="green"] {
    background-color: #e2f3ff;
}
#appCapsule .tile[data-color="ash"] {
    background-color: #f1e8ff;
}
#appCapsule .tile[data-color="pink"] {
    background-color: #ffe7fb;
}
#appCapsule .active {
    transition: scale 0.1s;
    :active {
        transform: scale(0.95);
        transform-origin: center;
        transition: scale 0.1s;
    }
}
#appCapsule .desktop-show {
    @media only screen and (max-width: 500px) {
        display: none;
        img {
            display: none;
        }
        div {
            display: none;
        }
        a {
            display: none;
        }
        span {
            display: none;
        }
    }
}
#appCapsule .mobile-show {
    @media only screen and (min-width: 500px) {
        display: none;
        img {
            display: none;
        }
        div {
            display: none;
        }
        a {
            display: none;
        }
        span {
            display: none;
        }
    }
}

.sortable-image-container {
    //visibility: visible !important;
    //opacity: 1 !important;
}
//#appCapsule .link-text:hover{
//	color: var(--bs-primary);
//}

.blur-background {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.status {
    width: 29.7px;
    height: 29.7px;
    background: #02bd04;
    border-radius: 50%;
}

.user-profile {
    color: #333333;

    .details {
        border-top: 0.1px solid #c4c4c4;
        border-bottom: 0.1px solid #c4c4c4;

        .left {
            font-size: 0.9rem;
            .title {
                margin-bottom: 0;
                font-weight: 700;
            }

            .subtitle {
                opacity: 0.7;
            }
        }

        .right {
            display: flex;
            align-items: center;

            figure {
                margin-bottom: 0;
            }

            .dropdown {
                button {
                    background: transparent !important;

                    svg {
                        position: relative;
                        bottom: 8px;
                    }
                }
            }
        }

        > div {
            height: 70px;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px 20px 40px;
        }

        > div:not(:first-child) {
            border-top: 0.1px solid #c4c4c4;
        }
    }
}

.user-management {
    color: #333333;

    .user-name {
        font-size: 0.95rem;
        font-weight: 600;
    }

    .user-email {
        font-size: 0.89rem;
        opacity: 0.7;
    }

    .users-holder > div:not(:first-child) {
        padding-top: 10px;
    }
}

.add-user-page {
    #input-tags div[data-testid="tag-element"] {
        background: rgba(43, 134, 255, 0.13) !important;
        color: #2b86ff !important;
    }

    select.form-select {
        background-color: #dddddd !important;
    }

    #input-tags {
        .form-control {
            background: #dddddd !important;
        }
    }
}
