.mobile-view {
  input.MuiInput-input.MuiInputBase-input {
    font-size: 15px;
  }

  div.MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
}

.calendar {
  .MuiFormControl-root.MuiTextField-root {
    height: 38px;
  }
}

.MuiFormControl-root.MuiTextField-root {
  height: 100%;

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl {
    height: 100%;
  }
}


