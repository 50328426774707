.form-group {
  margin-bottom: 1rem;
}

#appCapsule textarea,
#appCapsule .form-control {
  background-clip: padding-box;
  background-image: linear-gradient(transparent, transparent);
}
#appCapsule .form-control.input-new-rounded {
  background: #e6e6e6 !important;
  border-radius: 25px !important;
  height: 64px !important;
}
#appCapsule .floating-label-new-background {
  background: hsla(0,0%,100%,0) !important;
  color: #27173e;
  font-size: 13px;
  font-weight: 600;
  left: 15px;
  letter-spacing: .02em;
  line-height: 18px;
  padding: 0 5px;
  pointer-events: none;
  position: absolute;
  top: 20px;
  transition: all .2s ease;
  -moz-transition: all .2s ease;
  -webkit-transition: all .2s ease;
}
#appCapsule .form-group {
  width: 100%;
  .label {
    font-size: $fontSizeCaption;
    margin: 0;
    font-weight: $medium;
    color: $colorHeading;
    display: block;
    line-height: 1.2em;
    text-align: left;
  }
  .floating-label {
    color: $colorHeading;
    font-size: $fontSizeLabel;
    background: #fff;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 20px;
    line-height: 1.2em;
    padding: 0 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  textarea {
    resize: none;
  }
  .input-info {
    font-size: $fontSizeCaption;
    color: $colorLight;
  }
  .clear-input {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colorText;
    height: 38px;
    font-size: 22px;
    position: absolute;
    right: 5px;
    top: 10px;
    bottom: 0;
    width: 32px;
    opacity: 0.5;
    display: none;
    &:hover,
    &:active {
      opacity: 0.8;
    }
  }
  .input-wrapper {
    position: relative;
    &.not-empty {
      .floating-label-new-background {
        top: 4px;
      }
      .clear-input {
        display: flex;
      }
    }
    &.active {
      .label {
        color: $colorPrimary !important;
      }
    }
  }
}

#appCapsule .form-group.basic {
  padding: 8px 0;
  margin: 0;

  .form-control {
    background: transparent;
    border: 1px solid #dcdce9;
    border-radius: 5px;
    color: #27173e;
    font-size: 15px;
    height: 55px;
    padding: 0 30px 0 0;
  }

  .form-control,
  .custom-select {
    background: transparent;
    border: 1px solid $colorLine;
    padding: 0 30px 0 0;
    border-radius: 5px;
    height: 55px;
    color: $colorHeading;
    font-size: $fontSize;
    &:focus {
      border-color: $formBorderColor;
      box-shadow: inset 0 -1px 0 0 $formBorderColor;
    }
  }
  .floating-input,
  .floating-select {
    font-size: 12px;
    display: block;
    width: 100%;
    padding: 0 20px;
    color: #323840;
    border: 1px solid $formBorderColor;
    border-radius: 4px;
    box-sizing: border-box;
    &:focus {
      outline: none;
      ~ .floating-label-new-background {
        top: 4px;
        background: #fff;
        font-size: 12px;
      }
    }
  }
  select.floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  select.floating-select::-ms-expand {
    display: none;
  }
  .floating-input:not(:placeholder-shown) ~ .floating-label {
    top: -8px;
    font-size: 13px;
  }
  .floating-select:not([value=""]):valid ~ .floating-label {
    top:-8px;
    font-size:13px;
  }
  .floating-select[value=""]:focus ~ .floating-label {
    top:11px;
    font-size:13px;
  }
  .floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
  }
  .did-input-group {
    display: flex;
    .floating-input{
      border-radius:0 4px 4px 0;
      border-left:0;
      padding-left:0;
    }
  }
  .did-input-group-append {
    display:flex;
    align-items:center;
    /*   margin-left:-1px; */
  }
  .did-input-group-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #323840;
    padding: 0 5px 0 20px;
    font-size:12px;
    height: 55px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid $formBorderColor;
    border-radius: 4px 0 0 4px;
    border-right:none;
  }
  .custom-select {
    padding-right: 0;
  }
  textarea.form-control {
    height: auto;
    padding: 7px 40px 7px 0;
  }
}
#appCapsule .form-group.basic.animated {
  .label {
    margin-top: 20px;
    opacity: 0;
    top: -3px;
    transition: 0.2s all;
    position: absolute;
  }
  .input-wrapper {
    padding-top: 5px;
    & .not-empty {
      .label {
        margin-top: 0;
        opacity: 1;
      }
      & .floating-label-new-background{
          top: 8px !important;
      }
    }
  }
}

.verification-input {
  font-size: 32px !important;
  letter-spacing: 10px;
  text-align: center;
  border-radius: $borderRadius !important;
  border: 1px solid $colorLine !important;
  width: 180px !important;
  padding: 0 10px !important;
  margin: auto;
  min-height: 70px !important;
  font-weight: $bold;
  color: $colorHeading !important;
  box-shadow: none !important;
  background: #fff !important;
  &:focus {
    border-color: $colorHeading !important;
  }
}
#appCapsule .form-control-lg {
  font-size: 20px !important;
}
#appCapsule .form-select {
  box-shadow: 0 !important;
  outline: 0 !important;
  background-color: transparent;
}
#appCapsule .form-select-lg {
  height: 48px;
  font-size: 20px !important;
}
.input-group {
  .input-group-text {
    background: transparent;
    border: 0;
    border-bottom: 1px solid $colorLine;
    border-radius: 0;
    font-weight: $regular;
    color: $colorHeading;
    height: 40px;
    width: auto;
    padding: 0 10px 0 0;
  }

  .input-group-addon {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;

    > a {
      color: #333;
      &:hover {
        color: #333;
        cursor: pointer;
      }
    }

    svg {
      width: 40px;
      font-size: 16px;
    }
  }
}
.custom-file-upload {
  position: relative;
  display: flex;
  width: 100%;
  height: 220px;
  input[type="file"] {
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
  label {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: rgba($colorLine, 0.2);
    background-size: 45px 58px;
    border: 1px solid $colorLine;
    border-radius: $borderRadius;
    margin: 0;
    span {
      display: block;
      font-size: $fontSize;
      color: $colorLight;
      height: auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      font-weight: $medium;
      transform: translate(0%, -50%);
      align-items: center;
      justify-content: center;
      padding: 10px 16px;
      width: 100%;
      text-align: center;
      border-radius: 0;
      .icon,
      ion-icon {
        --ionicon-stroke-width: 32px;
        font-size: 48px;
        color: $colorLight;
      }
      strong {
        display: block;
        font-weight: $medium;
      }
      i {
        font-style: normal;
        display: block;
      }
    }
    &.file-uploaded {
      background-size: cover;
      background-position: center;
      &:before {
        content: "";
        width: 38px;
        height: 38px;
        background: $colorSuccess;
        border-radius: 100%;
        display: flex;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='23px' height='20px' viewBox='0 0 23 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline id='Path' stroke='%23FFFFFF' stroke-width='2.4' points='21.2 2 7.76 18 2 12'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        left: 50%;
        top: calc(50% + -22px);
        transform: translate(-50%, -50%);
      }
      label {
        border-color: transparent;
      }
      span {
        bottom: 0;
        left: 0;
        top: auto;
        transform: translate(0, 0);
        font-size: $fontSizeCaption;
        color: $colorText;
        background-color: $colorLine;
      }
    }
  }
}

#appCapsule .form-group.boxed {
  margin: 0;
  padding: 8px 0;
  .form-control {
    background: #fff;
    box-shadow: none;
    height: 42px;
    border-radius: $borderRadius;
    padding: 0 40px 0 16px;
    &:focus {
      border-color: $colorPrimary;
    }
  }
  textarea.form-control {
    height: auto;
    padding: 7px 40px 7px 16px;
  }
  .clear-input {
    right: 0;
    height: 42px;
    width: 40px;
  }
  .label {
    margin-bottom: 8px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//fix ios zoom effect
@supports (-webkit-touch-callout: none) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}


form.form-inline {
  display: flex;
}
