.pkg-signup {
  header:not(.main-section-header) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .1)), url('../../../../assets/img/pkg-signup-background.png') no-repeat center 15%/cover;
    padding-bottom: 150px;
  }

  div.gradient {
    background: url('../../../../assets/img/pkg-signup-gradient.png') no-repeat center 15%/cover;
    height: 10px;
  }

  .main-section {
    width: 40%;
    margin: 0 auto;

    form {
      > div:not(:first-child) {
        margin-top: 15px;
      }

      .agree-to-terms {
        button {
          border-radius: 20px !important;
          padding: 20px 0 !important;
        }
      }
    }
  }

  label {
    width: 100%;
    color: black;
    font-size: .9rem;
  }

  .form-control {
    outline: none;
    border-radius: 10px;
    width: 100%;
    font-size: .9rem;
  }
}